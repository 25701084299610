import "uikit";

import React, { useState, useEffect, useCallback } from "react";
import { hot } from "react-hot-loader";

import Analytics from "./Analytics";

const SignInButton = ({ gapi }) => {
  const authHandler = useCallback(() => {
    console.log("google auth success");
  }, []);

  useEffect(() => {
    gapi.signin2.render("g-signin2", {
      scope: "https://www.googleapis.com/auth/plus.login",
      width: 200,
      height: 50,
      longtitle: true,
      theme: "dark",
      onsuccess: authHandler
    });
  }, []);

  return <p id="g-signin2"></p>;
};

function App(props) {
  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    setLoggedIn(gapi.auth2.getAuthInstance().isSignedIn.get());

    gapi.auth2.getAuthInstance().isSignedIn.listen(signedIn => {
      setLoggedIn(signedIn);
    });
  }, []);

  return (
    <div className="uk-container">
      <h1>HP Scroll Prog Analytics</h1>
      {loggedIn ? (
        <Analytics gapi={props.gapi} />
      ) : (
        <SignInButton gapi={props.gapi} />
      )}
    </div>
  );
}

export default hot(module)(App);
