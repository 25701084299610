import React, { useState, useEffect } from "react";
import query, { parseData } from "./ga-query";

function percentify(number) {
  return Math.round(number * 100);
}

export default function(props) {
  const { gapi } = props;

  const [pageViewData, updatePVData] = useState([]);

  const [totalsPVs, setTotal] = useState(0);
  const [totalsScrolls, setScrolls] = useState(0);

  const loadTotals = () => {
    query(gapi, "30 days ago")
      .then(([pv, events]) => {
        let sum = 0;
        let scrolls = 0;

        const eventData = parseData(events).map(q => q.metrics[0].values[0]);

        const totals = parseData(pv).forEach((p, index) => {
          const pageViews = p.metrics[0].values[0];
          const scrollEvents = eventData[index];

          sum += parseInt(pageViews);
          scrolls += parseInt(scrollEvents);
        });

        console.log("Updating totals");

        setTotal(sum);
        setScrolls(scrolls / sum);
      })
      .catch(e => {
        console.error(e);
      });
  };

  const loadStoryStats = () => {
    query(gapi, "24 hours ago", false)
      .then(([pv, events]) => {
        let sum = 0;
        let scrolls = 0;

        const eventData = parseData(events, 10).map(
          q => q.metrics[0].values[0]
        );

        const totals = parseData(pv, 10).map((p, index) => {
          const slug = p.dimensions[0];
          const PV = p.metrics[0].values[0];

          const math = eventData[index] / PV;

          sum += parseInt(PV);
          scrolls += parseInt(eventData[index]);

          return {
            slug,
            pageViews: parseInt(PV),
            math
          };
        });

        updatePVData(totals);
      })
      .catch(e => {
        console.error(e);
      });
  };

  useEffect(() => {
    // calculate the totals
    loadTotals();
  }, []);

  useEffect(() => {
    loadStoryStats();
  }, []);

  useEffect(() => {
    const interval = setInterval(function() {
      loadTotals();
      loadStoryStats();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const tableData = pageViewData
    .sort((t1, t2) => t2.math - t1.math)
    .map((pv, index) => {
      return (
        <tr key={pv.slug}>
          <td>{pv.slug}</td>
          <td>{percentify(pv.math)}%</td>
          <td>{pv.pageViews.toLocaleString()}</td>
        </tr>
      );
    });

  const loaded = totalsPVs > 0 && pageViewData.length > 0;

  return (
    <>
      <div className="uk-flex">
        <span className="uk-text-lead uk-text-bold uk-margin-right">
          30-Day Average Scroll % = {percentify(totalsScrolls)}%
        </span>
        <span className="uk-text-lead uk-margin-right">|</span>
        <span className="uk-text-lead" style={{ flexGrow: 1 }}>
          30-Day Average Scroll PVs = {totalsPVs.toLocaleString()}
        </span>
        {!loaded && <div uk-spinner="true" />}
      </div>
      <table className="uk-table uk-table-striped uk-margin-large-bottom">
        <thead>
          <tr>
            <th>Article In Prog Scroll</th>
            <th>Scroll %</th>
            <th>Page Views</th>
          </tr>
        </thead>
        <tbody>{tableData}</tbody>
      </table>
    </>
  );
}
