const chrono = require("chrono-node");

const GAViewId = "132790853";

function fetchGAData(api, body) {
  return api.client.request({
    path: "/v4/reports:batchGet",
    root: "https://analyticsreporting.googleapis.com/",
    method: "POST",
    body
  });
}

function buildTimeWindow(startAt) {
  // this mess parses the dates and puts them into gRPC 8601 short form
  return {
    startDate: chrono
      .parseDate(startAt ? startAt : "24 hours ago")
      .toISOString()
      .substring(0, 10),
    endDate: chrono
      .parseDate("now")
      .toISOString()
      .substring(0, 10)
  };
}

function queryArticlePVs(viewId, startDate = null, includeBranded = true) {
  const timeWindow = buildTimeWindow(startDate);

  const body = {
    reportRequests: [
      {
        viewId,
        pageSize: 20,
        dateRanges: [timeWindow],
        metrics: [
          {
            expression: "ga:pageviews"
          }
        ],
        dimensions: [
          {
            name: "ga:dimension2"
          }
        ],
        dimensionFilterClauses: [
          {
            operator: "AND",
            filters: [
              {
                dimension_name: "ga:dimension3",
                operator: "EXACT",
                expressions: ["2"]
              }
            ]
          },
          {
            operator: "AND",
            filters: [
              {
                dimension_name: "ga:dimension11",
                operator: "EXACT",
                expressions: ["article"]
              }
            ]
          }
        ],
        orderBys: [
          {
            fieldName: "ga:pageviews",
            orderType: "VALUE",
            sortOrder: "DESCENDING"
          }
        ]
      }
    ]
  };

  if (includeBranded) {
    body.reportRequests[0].dimensionFilterClauses.push({
      operator: "AND",
      filters: [
        {
          dimension_name: "ga:keyword",
          operator: "REGEXP",
          not: true,
          expressions: ["BSProgressivescroll"]
        }
      ]
    });
  }

  return body;
}

function queryArticleScrollEvents(viewId, startDate = null, includeBranded) {
  const timeWindow = buildTimeWindow(startDate);

  const body = {
    reportRequests: [
      {
        viewId,
        pageSize: 20,
        dateRanges: [timeWindow],
        metrics: [
          {
            expression: "ga:totalEvents"
          }
        ],
        dimensions: [
          {
            name: "ga:dimension2"
          }
        ],
        dimensionFilterClauses: [
          {
            operator: "AND",
            filters: [
              {
                dimension_name: "ga:dimension3",
                operator: "EXACT",
                expressions: ["2"]
              }
            ]
          },
          {
            operator: "AND",
            filters: [
              {
                dimension_name: "ga:dimension11",
                operator: "EXACT",
                expressions: ["article"]
              }
            ]
          },
          {
            operator: "AND",
            filters: [
              {
                dimension_name: "ga:eventCategory",
                operator: "EXACT",
                expressions: ["article_scroll_depth"]
              }
            ]
          }
        ],
        orderBys: [
          {
            fieldName: "ga:totalEvents",
            orderType: "VALUE",
            sortOrder: "DESCENDING"
          }
        ]
      }
    ]
  };

  if (includeBranded) {
    body.reportRequests[0].dimensionFilterClauses.push({
      operator: "AND",
      filters: [
        {
          dimension_name: "ga:keyword",
          operator: "REGEXP",
          not: true,
          expressions: ["BSProgressivescroll"]
        }
      ]
    });
  }

  return body;
}

export function parseData(resData, maxRows = 20) {
  const data = resData.result.reports[0].data;

  return data.rows.slice(0, maxRows);
}

export default function(api, startDate, includeBranded) {
  const pageViewsPromise = fetchGAData(
    api,
    queryArticlePVs(GAViewId, startDate, includeBranded)
  );
  const scrollEventsPromise = fetchGAData(
    api,
    queryArticleScrollEvents(GAViewId, startDate, includeBranded)
  );

  return Promise.all([pageViewsPromise, scrollEventsPromise]);
}
