/* global gapi */

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

const clientID =
  "605940557985-h8bep2ms90jfu6k7ot3qqu8fu8f0nrk4.apps.googleusercontent.com";

function init() {
  console.log("init");

  gapi.load("client:auth2", () => {
    gapi.auth2
      .init({
        client_id: clientID,
        scopes: ["profile"]
      })
      .then(() => {
        ReactDOM.render(
          <App gapi={global.gapi} />,
          document.getElementById("react-root")
        );
      })
      .catch(e => console.error(e));
  });
}

if (module.hot) {
  module.hot.accept();
}

if (global.gapi) {
  console.log("gapi in cache");
  init();
}

global.init = init;
